import Layout from "../common/layout"

import DetailMediaBuyBackCompanyCrea from "../company/DetailMedia/DetailMediaBuyBackCompanyCrea"

import Head from "../common/Head"
import { useFormatter } from "helpers/i18n"
import OnpointCreaImg from "images/Home/News/onpoint-crea.webp"

const BuyBackCompanyCrea = () => {
  const { __ } = useFormatter()
  return (
    <>
      <Head
        showCaptcha={false}
        title={__({ defaultMessage: "Press Release" })}
        description={__({
          defaultMessage:
            "Vietnam's OnPoint acquires Thai e-commerce enabler CREA",
        })}
        image={OnpointCreaImg}
      />
      <Layout>
        <DetailMediaBuyBackCompanyCrea />
      </Layout>
    </>
  )
}

export default BuyBackCompanyCrea
