import {
  useIntl,
  changeLocale,
  IntlContextConsumer,
} from "gatsby-plugin-react-intl"

import { Link as GatsbyLink } from "gatsby"
import routes from "../routes/routes.json"

export function useFormatter() {
  const { formatMessage, locale: currentLocale } = useIntl()
  const Link = ({ to, locale, ...rest }) => {
    if (to) {
      if (!to.endsWith("/")) {
        to = to + "/"
      }
      if (locale && locale !== currentLocale) {
        if (currentLocale !== "en") {
          const localePrefix = "/" + currentLocale
          if (to.startsWith(localePrefix)) {
            to = to.substr(localePrefix.length)
          }
        }
        // Find the original route
        const currentLocaleMaps = routes[currentLocale]
        if (currentLocaleMaps) {
          const originalMap = Object.entries(currentLocaleMaps).find(
            ([_, value]) => value === to
          )
          if (originalMap) {
            to = originalMap[0]
          }
        }
      }

      locale = locale || currentLocale
      const routeMaps = routes[locale]
      if (routeMaps) {
        const newRoute = routeMaps[to]
        if (newRoute) {
          to = newRoute
        }
      }
      if (locale !== "en") {
        let localePrefix = "/" + locale
        if (!to.startsWith(localePrefix)) {
          to = localePrefix + to
        }
      }
    }

    if (rest?.resetPosition) {
      return <a href={to} {...rest} />
    }
    return <GatsbyLink to={to} {...rest} />
  }
  return {
    __: formatMessage,
    Link: Link,
    changeLocale: changeLocale,
    IntlContextConsumer: IntlContextConsumer,
    currentLocale: currentLocale,
  }
}
