import React, { useState } from "react"
import { useFormatter } from "../../helpers/i18n"
import { useAppContext } from "pages/common/layout"
import { validateAndSendEmail } from "helpers/feedback"
import ContactBgDesktopEn from "images/Home/Contact/contact-bg-desktop-en.png"
import ContactBgMobileEn from "images/Home/Contact/contact-bg-mobile-en.png"
import ContactBgDesktopVi from "images/Home/Contact/contact-bg-desktop-vi.png"
import ContactBgMobileVi from "images/Home/Contact/contact-bg-mobile-vi.png"
import FormBgDesktop from "images/Home/Contact/form-bg-desktop.png"
import FormBgMobile from "images/Home/Contact/form-bg-mobile.png"
import ContactSubmitBtn from "images/Home/Contact/contact-submit-btn.png"
import ContactSubmitBtnVi from "images/Home/Contact/contact-submit-btn-vi.png"

const emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"

export default function ContactForm() {
  const { currentLocale } = useFormatter()
  const { isMobile } = useAppContext()
  const [contactData, setContactData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const onSubmitContact = e => {
    if (
      !contactData.your_name ||
      !contactData.phone_number ||
      !contactData.email ||
      !contactData.company ||
      !contactData.position ||
      !contactData.industry ||
      !contactData.email?.match(emailPattern)
    ) {
      return
    }
    e.preventDefault()

    const params = {
      ...contactData,
      subject: `[${contactData.industry}][${contactData.position}] New Partner wants to connect with Onpoint`,
      type: "message_type",
    }

    setIsLoading(true)

    validateAndSendEmail(params).then(result => {
      if (result.success) {
        setContactData({})
        window.location.href = "/thank-you"
      } else {
        alert(result.message)
      }
    })

    setIsLoading(false)
  }

  const renderContentPage = () => {
    if (currentLocale === "vi" && isMobile) {
      return (
        <ContactFormMobileVi
          isLoading={isLoading}
          contactData={contactData}
          setContactData={setContactData}
          onSubmitContact={onSubmitContact}
        />
      )
    }

    if (currentLocale === "vi" && !isMobile) {
      return (
        <ContactFormDesktopVi
          isLoading={isLoading}
          contactData={contactData}
          setContactData={setContactData}
          onSubmitContact={onSubmitContact}
        />
      )
    }

    if (currentLocale === "en" && isMobile) {
      return (
        <ContactFormMobileEn
          isLoading={isLoading}
          contactData={contactData}
          setContactData={setContactData}
          onSubmitContact={onSubmitContact}
        />
      )
    }

    return (
      <ContactFormDesktopEn
        isLoading={isLoading}
        contactData={contactData}
        setContactData={setContactData}
        onSubmitContact={onSubmitContact}
      />
    )
  }

  return renderContentPage()
}

function ContactFormDesktopEn({
  isLoading,
  contactData,
  setContactData,
  onSubmitContact,
}) {
  const contactInputs = [
    {
      name: "your_name",
      label: "Name",
      id: "name-input",
    },
    {
      name: "phone_number",
      label: "Phone",
      id: "phone-input",
      inputProps: {
        type: "text",
        onInput: e => {
          e.target.value = e.target.value.replace(/[^0-9]/g, "")
        },
      },
    },
    {
      name: "email",
      label: "Email",
      id: "email-input",
      inputProps: {
        type: "email",
        pattern: emailPattern,
      },
    },
    {
      name: "company",
      label: "Company",
      id: "company-input",
    },
    {
      name: "position",
      label: "Position/Job title",
      id: "position-input",
    },
    {
      name: "industry",
      label: "Industry/Sector",
      id: "industry-input",
    },
    {
      name: "enquiry_information",
      label: "Questions for us (if any)",
      id: "enquiry-input",
      type: "textarea",
    },
  ]

  return (
    <div id="contact" className="relative">
      <img className="base-width-landing" src={ContactBgDesktopEn} />
      <div className="form-bg">
        <img className="form-img" src={FormBgDesktop} />
        <div className="form-container">
          <form className="contact-form">
            <div className="input-grid">
              {contactInputs.map(input => {
                if (input.type === "textarea") {
                  return (
                    <div className="textarea-container">
                      <label className="contact-label" htmlFor={input.id}>
                        {input.label}
                      </label>
                      <textarea
                        id={input.id}
                        className="contact-textarea"
                        required={true}
                        disabled={isLoading}
                        onChange={e =>
                          setContactData({
                            ...contactData,
                            [input.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  )
                }
                return (
                  <div className="input-container">
                    <label className="contact-label" htmlFor={input.id}>
                      {input.label}
                    </label>
                    <input
                      id={input.id}
                      className="contact-input"
                      required={true}
                      disabled={isLoading}
                      onChange={e =>
                        setContactData({
                          ...contactData,
                          [input.name]: e.target.value,
                        })
                      }
                      {...input.inputProps}
                    />
                  </div>
                )
              })}
            </div>
            <div className="action-container">
              <button
                className="contact-submit-btn"
                type="submit"
                disabled={isLoading}
                onClick={onSubmitContact}
              >
                <img className="contact-submit-img" src={ContactSubmitBtn} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

function ContactFormDesktopVi({
  isLoading,
  contactData,
  setContactData,
  onSubmitContact,
}) {
  const contactInputs = [
    {
      name: "your_name",
      label: "Tên",
      id: "name-input",
    },
    {
      name: "phone_number",
      label: "Số điện thoại",
      id: "phone-input",
      inputProps: {
        type: "text",
        onInput: e => {
          e.target.value = e.target.value.replace(/[^0-9]/g, "")
        },
      },
    },
    {
      name: "email",
      label: "Email",
      id: "email-input",
      inputProps: {
        type: "email",
        pattern: emailPattern,
      },
    },
    {
      name: "company",
      label: "Công ty",
      id: "company-input",
    },
    {
      name: "position",
      label: "Vị trí/Chức vụ",
      id: "position-input",
    },
    {
      name: "industry",
      label: "Ngành/Lĩnh vực",
      id: "industry-input",
    },
    {
      name: "enquiry_information",
      label: "Câu hỏi cho chúng tôi (nếu có)",
      id: "enquiry-input",
      type: "textarea",
    },
  ]

  return (
    <div id="contact" className="relative">
      <img className="base-width-landing" src={ContactBgDesktopVi} />
      <div className="form-bg">
        <img className="form-img" src={FormBgDesktop} />
        <div className="form-container">
          <form className="contact-form">
            <div className="input-grid">
              {contactInputs.map(input => {
                if (input.type === "textarea") {
                  return (
                    <div className="textarea-container">
                      <label className="contact-label" htmlFor={input.id}>
                        {input.label}
                      </label>
                      <textarea
                        id={input.id}
                        className="contact-textarea"
                        required={true}
                        disabled={isLoading}
                        onChange={e =>
                          setContactData({
                            ...contactData,
                            [input.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  )
                }
                return (
                  <div className="input-container">
                    <label className="contact-label" htmlFor={input.id}>
                      {input.label}
                    </label>
                    <input
                      id={input.id}
                      className="contact-input"
                      required={true}
                      disabled={isLoading}
                      onChange={e =>
                        setContactData({
                          ...contactData,
                          [input.name]: e.target.value,
                        })
                      }
                      {...input.inputProps}
                    />
                  </div>
                )
              })}
            </div>
            <div className="action-container">
              <button
                className="contact-submit-btn"
                type="submit"
                disabled={isLoading}
                onClick={onSubmitContact}
              >
                <img className="contact-submit-img" src={ContactSubmitBtnVi} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

function ContactFormMobileEn({
  isLoading,
  contactData,
  setContactData,
  onSubmitContact,
}) {
  const contactInputs = [
    {
      name: "your_name",
      label: "Name",
      id: "name-input",
    },
    {
      name: "phone_number",
      label: "Phone",
      id: "phone-input",
      inputProps: {
        type: "text",
        onInput: e => {
          e.target.value = e.target.value.replace(/[^0-9]/g, "")
        },
      },
    },
    {
      name: "email",
      label: "Email",
      id: "email-input",
      inputProps: {
        type: "email",
        pattern: emailPattern,
      },
    },
    {
      name: "company",
      label: "Company",
      id: "company-input",
    },
    {
      name: "position",
      label: "Position/Job title",
      id: "position-input",
    },
    {
      name: "industry",
      label: "Industry/Sector",
      id: "industry-input",
    },
    {
      name: "enquiry_information",
      label: "Questions for us (if any)",
      id: "enquiry-input",
      type: "textarea",
    },
  ]

  return (
    <div id="contact" className="relative">
      <img className="base-width-landing" src={ContactBgMobileEn} />
      <div className="form-bg">
        <img className="form-img" src={FormBgMobile} />
        <div className="form-container">
          <form className="contact-form">
            <div className="input-grid">
              {contactInputs.map(input => {
                if (input.type === "textarea") {
                  return (
                    <div className="textarea-container">
                      <label className="contact-label" htmlFor={input.id}>
                        {input.label}
                      </label>
                      <textarea
                        id={input.id}
                        className="contact-textarea"
                        required={true}
                        disabled={isLoading}
                        onChange={e =>
                          setContactData({
                            ...contactData,
                            [input.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  )
                }
                return (
                  <div className="input-container">
                    <label className="contact-label" htmlFor={input.id}>
                      {input.label}
                    </label>
                    <input
                      id={input.id}
                      className="contact-input"
                      required={true}
                      disabled={isLoading}
                      onChange={e =>
                        setContactData({
                          ...contactData,
                          [input.name]: e.target.value,
                        })
                      }
                      {...input.inputProps}
                    />
                  </div>
                )
              })}
            </div>
            <div className="action-container">
              <button
                className="contact-submit-btn"
                type="submit"
                disabled={isLoading}
                onClick={onSubmitContact}
              >
                <img className="contact-submit-img" src={ContactSubmitBtn} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

function ContactFormMobileVi({
  isLoading,
  contactData,
  setContactData,
  onSubmitContact,
}) {
  const contactInputs = [
    {
      name: "your_name",
      label: "Tên",
      id: "name-input",
    },
    {
      name: "phone_number",
      label: "Số điện thoại",
      id: "phone-input",
      inputProps: {
        type: "text",
        onInput: e => {
          e.target.value = e.target.value.replace(/[^0-9]/g, "")
        },
      },
    },
    {
      name: "email",
      label: "Email",
      id: "email-input",
      inputProps: {
        type: "email",
        pattern: emailPattern,
      },
    },
    {
      name: "company",
      label: "Công ty",
      id: "company-input",
    },
    {
      name: "position",
      label: "Vị trí/Chức vụ",
      id: "position-input",
    },
    {
      name: "industry",
      label: "Ngành/Lĩnh vực",
      id: "industry-input",
    },
    {
      name: "enquiry_information",
      label: "Câu hỏi cho chúng tôi (nếu có)",
      id: "enquiry-input",
      type: "textarea",
    },
  ]

  return (
    <div id="contact" className="relative">
      <img className="base-width-landing" src={ContactBgMobileVi} />
      <div className="form-bg">
        <img className="form-img" src={FormBgMobile} />
        <div className="form-container">
          <form className="contact-form">
            <div className="input-grid">
              {contactInputs.map(input => {
                if (input.type === "textarea") {
                  return (
                    <div className="textarea-container">
                      <label className="contact-label" htmlFor={input.id}>
                        {input.label}
                      </label>
                      <textarea
                        id={input.id}
                        className="contact-textarea"
                        required={true}
                        disabled={isLoading}
                        onChange={e =>
                          setContactData({
                            ...contactData,
                            [input.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  )
                }
                return (
                  <div className="input-container">
                    <label className="contact-label" htmlFor={input.id}>
                      {input.label}
                    </label>
                    <input
                      id={input.id}
                      className="contact-input"
                      required={true}
                      disabled={isLoading}
                      onChange={e =>
                        setContactData({
                          ...contactData,
                          [input.name]: e.target.value,
                        })
                      }
                      {...input.inputProps}
                    />
                  </div>
                )
              })}
            </div>
            <div className="action-container">
              <button
                className="contact-submit-btn"
                type="submit"
                disabled={isLoading}
                onClick={onSubmitContact}
              >
                <img className="contact-submit-img" src={ContactSubmitBtnVi} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
