import "./BannerHeader.scss"
import ContainerMain from "src/pages/common/ContainerMain"
import React from "react"
import { useFormatter } from "src/helpers/i18n"
export default function BannerHeader({
  breadcrumb = [],
  banner,
  mobileBanner,
  logo,
  listTitle = [],
  description,
  breadcrumbColor = "#fff",
}) {
  const { Link } = useFormatter()
  return (
    <div className="wrapper">
      <div className="RNDniyVkqZ-home">
        <div className="vinzgxtase">
          <div className="background-wrapper">
            <div className="background">{banner}</div>
            <div className="background-mobile">{mobileBanner}</div>
          </div>

          <div className="banner-content">
            <ContainerMain className="banner-content-wrapper">
              <div className="lhdxmwizjl">
                {breadcrumb?.map((item, index) => (
                  <Link
                    key={index}
                    to={item?.url ?? "#"}
                    style={{ color: breadcrumbColor }}
                  >
                    {breadcrumb?.length - 1 === index ? (
                      <b>{item.label}</b>
                    ) : (
                      item.label
                    )}
                  </Link>
                ))}
              </div>
              <div className="zbliJAztBb">
                <div className="header-content">
                  {logo ? (
                    <div className="wmPLOxTAul">
                      <img alt="" className="UKIJmWuMCN" src={logo} />
                    </div>
                  ) : null}
                  <div className="tbwjbtHNhe">
                    {listTitle?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="section-title MdsIbDKLPI"
                          dangerouslySetInnerHTML={{ __html: item }}
                        ></div>
                      )
                    })}
                  </div>
                  <div className="jzjTyOOeZU">{description}</div>
                </div>
              </div>
            </ContainerMain>
          </div>
        </div>
      </div>
    </div>
  )
}
