import ContainerMain from "src/pages/common/ContainerMain"
import Slider from "react-slick/lib/slider"
import prevSliderIcon from "images/prev_slider_incon_gray.svg"
import nextSliderIcon from "images/next_slider_incon_gray.svg"
import "./PressCoverage.scss"
import { useFormatter } from "helpers/i18n"
import { news, newsVN } from "data/news"

export default function PressCoverage() {
  const { __, currentLocale } = useFormatter()

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const renderNews = () => {
    const _news = currentLocale === "vi" ? newsVN : news

    return _news.map((item, index) => {
      return (
        <div className="EePOugTXGX" key={index}>
          <NewsCard data={item} />
        </div>
      )
    })
  }

  return (
    <>
      <div className="media-press-coverage">
        <div className="WdZHPaJUsL">
          <ContainerMain>
            <div className="section-title svoVqdrGDg">
              {__({
                defaultMessage: "Press Coverage",
              })}
            </div>
            <Slider className="bYrVNJdzkW" {...settings}>
              {renderNews()}
            </Slider>
          </ContainerMain>
        </div>
      </div>
    </>
  )
}

const NewsCard = ({ data }) => {
  const { __, Link } = useFormatter()

  return (
    <div className="media-item">
      <div className="zGDsKXiVOp">
        <div className="media-img">
          <div className="img-media_wrapper">
            <img alt="image" className="SJVEIkshKX" src={data.image} />
          </div>
        </div>
        <div className="BSxRUWALKW">
          <div className="kQIUVgbbKj">
            <div className="FXIQCzUdUS">Media</div>
          </div>
          <div className="mWlYDoscNs">{data.timer}</div>
        </div>
        <a className="OKZiCgTrQx" href={data.href} target="_blank">
          {data.title}
        </a>
        <div className="IpbDjzWUoq">{data.description}</div>
        <div className="btn-read-more">
          <a
            href={data.href}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-learn-more background-red"
          >
            {__({
              defaultMessage: "Read More",
            })}
          </a>
        </div>
      </div>
    </div>
  )
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={nextSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#777777", fontSize: 14 }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={prevSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#777777", fontSize: 14 }}
      onClick={onClick}
    />
  )
}
