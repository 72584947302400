import Layout from "./common/layout"
import Head from "./common/Head"
import AiShortVideo from "pages/product/AiShortVideo"

const AiShortVideoPage = () => {
  return (
    <>
      <Head
        title={"AI Short Video"}
        description={"AI Short Video"}
        showCaptcha={true}
      />
      <Layout displayApplyBtn={false}>
        <AiShortVideo />
      </Layout>
    </>
  )
}

export default AiShortVideoPage
