import image3 from "images/Home/News/image-3.svg"
import image1 from "images/Home/News/image-1.svg"
import image2 from "images/Home/News/image-2.svg"
import image7 from "images/Home/News/image-7.svg"
import image8 from "images/Home/News/image-8.jpeg"
import image9 from "images/Home/News/image-9.jpeg"
import image11 from "images/Home/News/image-11.jpeg"
import image12 from "images/Home/News/image-12.jpeg"
import image13 from "images/Home/News/onpoint-crea.webp"
import BestManagedCompaniesInVietnam2024 from "images/Company/About/BestManagedCompaniesInVietnam2024.png"

export const news = [
  {
    id: 5,
    by: "onpoint",
    image: image13,
    title:
      "OnPoint Acquires CREA to Strengthen E-Commerce Leadership in Southeast Asia",
    timer: "18/12/2024",
    description:
      "OnPoint, Vietnam’s leading E-commerce enabler, today officially announced its acquisition of CREA, one of Thailand’s foremost e-commerce enablers.",
    href: "/media/buy-back-company-crea/",
  },
  {
    id: 1,
    by: "deloitte",
    image: BestManagedCompaniesInVietnam2024,
    title: "OnPoint is a winner of Vietnam's Best Managed Companies for 2024",
    timer: "29/08/2024",
    description:
      "VIETNAM, August 19, 2024 – OnPoint has been presented with the prestigious 2024 Vietnam’s Best Managed Companies award for its industry-leading performance, excellent business practices and sustained growth.",
    href: "/media/best-managed-companies-2024/",
  },
  {
    id: 2,
    by: "techinasia",
    image: image3,
    title: "Temasek unit’s fund pumps $50m into Vietnam ecommerce enabler",
    timer: "25/06/2022",
    description:
      "OnPoint’s founder and CEO, Tran Vu Quang, said the company will use the fresh funds to expand its ecosystem, recruit talent, and develop emerging technologies via a data-driven approach. OnPoint will also tap into SeaTown’s vast networks and experience in the global digital economy and consumer sectors.",
    href: "https://www.techinasia.com/temaseks-seatown-injects-50m-vietnamese-ecommerce-enabler",
  },
  {
    id: 3,
    by: "technode.global",
    image: image1,
    title:
      "Vietnam's OnPoint closes $50M Series B funding led by Temasek's fund",
    timer: "24/06/2022",
    description:
      "OnPoint said in a statement that SeaTown’s investment will be the largest private investment in the Southeast Asia (SEA) e-commerce enabler industry in the last five years.",
    href: "https://technode.global/2022/06/24/vietnams-onpoint-closes-50m-series-b-funding-led-by-temaseks-fund/",
  },
  {
    id: 4,
    by: "vccinews",
    image: image7,
    title:
      "OnPoint Closes Largest Series B Round for SEA E-commerce Enabler Led by SeaTown Holdings International",
    timer: "27/06/2022",
    description:
      "OnPoint (“the Company”), the leading e-commerce enabler in Vietnam, announced the signing of its Series B fundraising round with SeaTown Private Capital Master Fund, a fund managed by SeaTown Holdings International (“SeaTown”), an indirect wholly owned subsidiary of Temasek Holdings.",
    href: "https://vccinews.com/news/47954/onpoint-closes-largest-series-b-round-for-sea-e-commerce-enabler-led-by-seatown-holdings-international.html",
  },
]

export const newsVN = [
  {
    id: 8,
    by: "onpoint",
    image: image13,
    title:
      "OnPoint Mua Lại CREA, Củng Cố Vị Thế Dẫn Đầu Ngành Thương Mại Điện Tử tại Đông Nam Á",
    timer: "18/12/2024",
    description:
      "OnPoint - nhà cung cấp các dịch vụ TMĐT hàng đầu Việt Nam chính thức công bố thương vụ mua lại CREA, một trong những công ty hàng đầu về TMĐT tại Thái Lan.",
    href: "/vi/truyen-thong/mua-lai-cong-ty-thai-nham-mo-rong-mang-thuong-mai-dien-tu/",
  },
  {
    id: 1,
    by: "deloitte",
    image: BestManagedCompaniesInVietnam2024,
    title:
      "OnPoint được vinh danh là Doanh nghiệp được Quản trị Tốt nhất Việt Nam 2024",
    timer: "29/08/2024",
    description:
      "TP. Hồ Chí Minh, ngày 19/8/2024 – OnPoint đã được vinh danh là Doanh nghiệp được Quản trị Tốt nhất 2024 nhờ những thành tích dẫn đầu trong ngành nghề, thực tiễn kinh doanh xuất sắc và phát triển bền vững.",
    href: "/vi/truyen-thong/doanh-nghiep-duoc-quan-tri-tot-nhat-viet-nam-2024/",
  },
  {
    id: 2,
    by: "thanhnien",
    image: image8,
    title:
      "Doanh nhân trẻ xuất sắc, tiêu biểu nhất TP.HCM năm 2022 là những ai?",
    timer: "17/12/2022",
    description:
      "Lễ trao giải thưởng “Doanh nhân trẻ xuất sắc TPHCM” năm 2022 do Thành đoàn, Hội Liên hiệp Thanh niên TP.HCM và Hội Doanh nhân trẻ TP.HCM tổ chức đã diễn ra vào tối 16.12. Những ai đã vinh dự được nhận giải thưởng này?",
    href: "https://thanhnien.vn/doanh-nhan-tre-xuat-sac-tieu-bieu-nhat-tphcm-nam-2022-la-nhung-ai-post1532976.html?zarsrc=30&utm_campaign=zalo&utm_medium=zalo&utm_source=zalo",
  },
  {
    id: 3,
    by: "thanhnien",
    image: image9,
    title:
      "Chủ tịch Phan Văn Mãi: “Lãnh đạo thành phố rất tin tưởng vào doanh nhân trẻ“",
    timer: "17/12/2022",
    description:
      "Lễ trao giải thưởng “Doanh nhân trẻ xuất sắc TPHCM” năm 2022 do Thành đoàn, Hội Liên hiệp Thanh niên TP.HCM và Hội Doanh nhân trẻ TP.HCM tổ chức đã diễn ra vào tối 16.12. Những ai đã vinh dự được nhận giải thưởng này?",
    href: "https://thanhnien.vn/chu-tich-phan-van-mai-lanh-dao-thanh-pho-rat-tin-tuong-vao-doanh-nhan-tre-post1532817.html",
  },
  {
    id: 4,
    by: "hcmcpv",
    image: image11,
    title:
      "Doanh nhân trẻ khích lệ tinh thần lập thân, lập nghiệp của thanh niên",
    timer: "16/12/2022",
    description:
      "(NLĐO)- Các doanh nhân trẻ vừa sản xuất giỏi vừa chăm lo, đào tạo phát triển tốt đội ngũ công nhân lao động và thực hiện các hoạt động nghĩa tình hướng đến cộng đồng.",
    href: "https://nld.com.vn/kinh-te/doanh-nhan-tre-khich-le-tinh-than-lap-than-lap-nghiep-cua-thanh-nien-20221216201843087.htm",
  },
  {
    id: 5,
    by: "nhandan",
    image: image12,
    title: "Trao giải thưởng “Doanh nhân trẻ xuất sắc Thành phố Hồ Chí Minh”",
    timer: "16/12/2022",
    description:
      "NDO - Tối 16/12, Hội Liên hiệp Thanh niên và Hội Doanh nhân trẻ Thành phố Hồ Chí Minh tổ chức trao giải thưởng “Doanh nhân trẻ xuất sắc Thành phố Hồ Chí Minh” lần thứ 11 năm 2022 nhằm tôn vinh các doanh nhân trẻ xuất sắc, doanh nhân trẻ tiêu biểu trong các lĩnh vực đang hoạt động sản xuất, kinh doanh trên địa bàn thành phố.",
    href: "https://nhandan.vn/trao-giai-thuong-doanh-nhan-tre-xuat-sac-thanh-pho-ho-chi-minh-post730271.html",
  },
  {
    id: 6,
    by: "thanhnien",
    image: image1,
    title: "OnPoint gọi vốn thành công 50 triệu USD từ SeaTown Holdings",
    timer: "25/06/2022",
    description:
      "Công ty OnPoint cung cấp giải pháp hỗ trợ thương mại điện tử đã gọi vốn thành công từ một thành viên của Temasek Holdings. Ngày 24.6, OnPoint - đơn vị cung cấp các giải pháp hỗ trợ phát triển thương mại điện tử tại Việt Nam - công bố gọi vốn thành công từ quỹ đầu tư SeaTown Private Capital Master Fund thuộc SeaTown Holdings International, thành viên của Temasek Holdings.",
    href: "https://thanhnien.vn/onpoint-goi-von-thanh-cong-50-trieu-usd-tu-seatown-holdings-post1471753.html",
  },
  {
    id: 7,
    by: "techsignin",
    image: image2,
    title:
      "Thương vụ OnPoint là lần gọi vốn lớn nhất trong mảng dịch vụ hỗ trợ phát triển thương mại điện tử tại khu vực Đông Nam Á",
    timer: "24/06/2022",
    description:
      "Chiều nay 24.6 tại TP.HCM, OnPoint – đơn vị cung cấp các giải pháp hỗ trợ phát triển thương mại điện tử hàng đầu tại Việt Nam – công bố lễ ký kết gọi vốn thành công từ SeaTown Private Capital Master Fund, quỹ đầu tư trực thuộc SeaTown Holdings International, một thành viên của Temasek Holdings.",
    href: "https://www.techsignin.com/onpoint-goi-von-seatown-holdings/",
  },
]
