import React from "react"

export default function AbsoluteImage({ children, width = "100%", ...props }) {
  return (
    <div style={{ position: "relative" }}>
      <img {...props} style={{ ...props.style, width: width }} />
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          style: { ...child.props.style, position: "absolute" },
        })
      })}
    </div>
  )
}
