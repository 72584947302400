import { useState, useEffect, useRef } from "react"
import ThankBg from "./assets/desktop/thank-bg.png"
import ThankBgVi from "./assets/desktop/thank-bg-vi.png"
import ThankBgMobile from "./assets/mobile/thank-bg.png"
import ThankBgMobileVi from "./assets/mobile/thank-bg-vi.png"
import LinkedInIcon from "./assets/desktop/linkedin-icon.png"
import FacebookIcon from "./assets/desktop/facebook-icon.png"
import "./assets/scss/index.scss"
import { useFormatter } from "helpers/i18n"

function ThankYou() {
  const { currentLocale } = useFormatter()
  const [isMobile, setIsMobile] = useState(false)

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768)
  }

  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      setTimeout(() => {
        const targetElement = document.querySelector(hash)
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" })
        }
      }, 500)
    }

    checkMobile()
    window.addEventListener("resize", checkMobile)
    return () => window.removeEventListener("resize", checkMobile)
  }, [])

  const renderContentPage = () => {
    if (currentLocale === "vi") {
      if (isMobile) {
        return <MobileThankPageVi />
      }
      return <DesktopThankPageVi />
    }

    if (isMobile) {
      return <MobileThankPage />
    }

    return <DesktopThankPage />
  }
  return renderContentPage()
}

function DesktopThankPage() {
  return (
    <div className="app-landingpage base-width-landing">
      <div className="landingpage content-center relative">
        <img className="base-width-landing" src={ThankBg} />
        <div className="social-contact-bg">
          <div className="contact-container">
            <div className="contact-grid">
              <div className="contact-column">
                <p>
                  Follow us on LinkedIn/Facebook to
                  <br />
                  stay updated with the latest news.
                </p>
              </div>
              <a
                className="contact-column"
                target="_blank"
                href="https://www.facebook.com/OnPointVietnam/"
                _
              >
                <img className="social-icon" src={FacebookIcon} />
              </a>
              <a
                className="contact-column"
                target="_blank"
                href="https://www.linkedin.com/company/onpointvn/"
              >
                <img className="social-icon" src={LinkedInIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function DesktopThankPageVi() {
  return (
    <div className="app-landingpage base-width-landing">
      <div className="landingpage content-center relative">
        <img className="base-width-landing" src={ThankBgVi} />
        <div className="social-contact-bg">
          <div className="contact-container">
            <div className="contact-grid">
              <div className="contact-column">
                <p>
                  Theo dõi chúng tôi trên LinkedIn/Facebook
                  <br />
                  để cập nhật tin tức mới nhất.
                </p>
              </div>
              <a
                className="contact-column"
                target="_blank"
                href="https://www.facebook.com/OnPointVietnam/"
                _
              >
                <img className="social-icon" src={FacebookIcon} />
              </a>
              <a
                className="contact-column"
                target="_blank"
                href="https://www.linkedin.com/company/onpointvn/"
              >
                <img className="social-icon" src={LinkedInIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function MobileThankPage() {
  return (
    <div className="app-landingpage base-width-landing">
      <div className="landingpage content-center relative">
        <img className="base-width-landing" src={ThankBgMobile} />
        <div className="social-contact-bg">
          <div className="contact-container">
            <div className="contact-grid">
              <div className="contact-column">
                <p>
                  Follow us on LinkedIn/Facebook to
                  <br />
                  stay updated with the latest news.
                </p>
              </div>
              <div className="contact-column">
                <a
                  target="_blank"
                  href="https://www.facebook.com/OnPointVietnam/"
                  _
                >
                  <img className="social-icon" src={FacebookIcon} />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/onpointvn/"
                >
                  <img className="social-icon" src={LinkedInIcon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function MobileThankPageVi() {
  return (
    <div className="app-landingpage base-width-landing">
      <div className="landingpage content-center relative">
        <img className="base-width-landing" src={ThankBgMobileVi} />
        <div className="social-contact-bg">
          <div className="contact-container">
            <div className="contact-grid">
              <div className="contact-column">
                <p>
                  Theo dõi chúng tôi trên LinkedIn/Facebook
                  <br />
                  để cập nhật tin tức mới nhất.
                </p>
              </div>
              <div className="contact-column">
                <a
                  target="_blank"
                  href="https://www.facebook.com/OnPointVietnam/"
                  _
                >
                  <img className="social-icon" src={FacebookIcon} />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/onpointvn/"
                >
                  <img className="social-icon" src={LinkedInIcon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThankYou
